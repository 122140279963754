import "./App.css"
//import { Uploader } from "./utils/upload"
import React, { useState } from "react"
import Modal from 'react-modal'; 
import logo from "./utils/servlet.png"
import axios from "axios"
import { v4 as uuidv4 } from 'uuid';

// initializing axios
const api = axios.create({
  baseURL: "https://u8z48hqpl3.execute-api.us-east-2.amazonaws.com/dev",
})

function App() {
  const [cargo,setCargo] = useState(undefined)
  const [email, setEmail] = useState(undefined)
  const [empresa, setEmpresa] = useState(undefined)
  const [nombre, setNombre] = useState(undefined)
  const [observacion, setObservacion] = useState(undefined)
  const [telefono, setTelefono] = useState(undefined)
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsError, setModalIsError] = useState(false);

  const handleClick = async (e) => {
    e.preventDefault();

    const uploaderOptions = {
      id: uuidv4(),
      cargo: cargo,
      email: email,
      empresa: empresa,
      nombre: nombre,
      observacion:observacion,
      telefono:telefono
    }
    
    try {  
      const initializeReponse = api.request({
        url: "/insert",
        method: "POST",
        data: uploaderOptions,
      })
      
      const AWSFileDataOutput = await initializeReponse.data
      console.log("AWSFileDataOutput: ", AWSFileDataOutput)
      // Aquí puedes manejar la lógica cuando se haga clic en el botón
      setCargo("");
      setEmail("");
      setEmpresa("");
      setNombre("");
      setObservacion("");
      setTelefono("");

      setModalIsOpen(true);

    } catch (error) {
      console.log(error)
      setModalIsError(true);
    }
    
    

  };

  const closeModal = () => {
    // Cierra el modal y realiza cualquier acción necesaria
    setModalIsOpen(false);
    setModalIsError(false)
  };

  return (
    <div>
      

      <div className="App">

        <form className="form-container">

          <div style={{ textAlign: 'center'}}>
            <img src={logo} alt="Logo de mi empresa" style={{ alignItems:"center" }} />
          </div>

          <h2>AWS SUMMIT BOGOTA</h2>

          <div style={{ backgroundColor: "#e2e2e2", padding: "20px"}}>    
            
            <strong style={{display: "block", marginBottom: "7.5px",fontSize: "16px"}}>Tu Cargo</strong>
            <input type="text" id="cargo" style={{width: "100%", height:"25px",fontSize: "16px",borderRadius: "2.5px"}} 
                  value={cargo}
                  onChange={(e) => {
                    setCargo(e.target?.value)
                  }}
            /><br/><br/>
            
            <strong style={{display: "block", marginBottom: "7.5px",fontSize: "16px"}}>Correo electrónico</strong>
            <input type="text" id="email" style={{width: "100%", height:"25px",fontSize: "16px",borderRadius: "2.5px"}} 
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target?.value)
                  }}
            /><br/><br/>

            <strong style={{display: "block", marginBottom: "7.5px",fontSize: "16px"}}>Tu Empresa</strong>
            <input type="text" id="empresa" style={{width: "100%", height:"25px",fontSize: "16px",borderRadius: "2.5px"}} 
                  value={empresa}
                  onChange={(e) => {
                    setEmpresa(e.target?.value)
                  }}
            /><br/><br/>

            <strong style={{display: "block", marginBottom: "7.5px",fontSize: "16px"}}>Tu Nombre</strong>
            <input type="text" id="nombre" style={{width: "100%", height:"25px",fontSize: "16px",borderRadius: "2.5px"}} 
                  value={nombre}
                  onChange={(e) => {
                    setNombre(e.target?.value)
                  }}
            /><br/><br/>

            <strong style={{display: "block", marginBottom: "7.5px",fontSize: "16px"}}>Observaciones</strong>
            <input type="text" id="observacion" style={{width: "100%", height:"25px",fontSize: "16px",borderRadius: "2.5px"}} rows={2} 
                  value={observacion}
                  onChange={(e) => {
                    setObservacion(e.target?.value)
                  }}
            /><br/><br/>

            <strong style={{display: "block", marginBottom: "7.5px",fontSize: "16px"}}>Tu Telefono</strong>
            <input type="text" id="telefono" style={{width: "100%", height:"25px",fontSize: "16px",borderRadius: "2.5px"}} 
                  value={telefono}
                  onChange={(e) => {
                    setTelefono(e.target?.value)
                  }}
            /><br/><br/>

            <button onClick={handleClick} style={{width: "100%", height:"40px",fontSize: "16px",borderRadius: "2.5px"}} >Registrar</button>

          </div>

        </form>

      </div>

      {/* Modal */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Ejemplo de Modal"
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
          },
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '60%', // Personaliza el ancho del modal según tus necesidades
            maxHeight: '80%', // Personaliza la altura máxima del modal según tus necesidades
            overflow: 'auto' // Permite el desplazamiento si el contenido es demasiado largo
          }
        }}
      >
        <div style={{ textAlign: 'center' }}>
          <h2>Datos enviados con éxito</h2>
          <p>Tus datos se han enviado correctamente.</p>
          <button onClick={closeModal} style={{width: "50%", height:"40px",fontSize: "16px",borderRadius: "2.5px"}}>Cerrar</button>
        </div>
      </Modal>
      
      {/* Modal */}
      <Modal
        isOpen={modalIsError}
        onRequestClose={closeModal}
        contentLabel="Ejemplo de Modal"
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
          },
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '60%', // Personaliza el ancho del modal según tus necesidades
            maxHeight: '80%', // Personaliza la altura máxima del modal según tus necesidades
            overflow: 'auto' // Permite el desplazamiento si el contenido es demasiado largo
          }
        }}
      >
        <div style={{ textAlign: 'center' }}>
          <h2>El envio ha fallado, vuelva a intentar luego</h2>
          <p>Tus datos no se han enviado.</p>
          <button onClick={closeModal} style={{width: "50%", height:"40px",fontSize: "16px",borderRadius: "2.5px"}}>Cerrar</button>
        </div>
      </Modal>

    </div>
  )
}

export default App
